var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-navbar",
    {
      staticClass: "header",
      attrs: { toggleable: "md", type: "dark", fixed: "top" }
    },
    [
      _c(
        "b-navbar-brand",
        { staticClass: "m-0 p-0 mr-4", attrs: { id: "logo", to: "/" } },
        [
          _c("img", {
            staticClass: "m-3",
            attrs: { src: require("@/assets/logo.png"), alt: "Webmag Logo" }
          })
        ]
      ),
      _c("b-navbar-toggle", { attrs: { target: "nav-collapse" } }),
      _c(
        "b-collapse",
        { attrs: { id: "nav-collapse", "is-nav": "" } },
        [
          _vm.userObject && _vm.currentPageType
            ? _c("header-breadcrumb", {
                attrs: {
                  "user-object": _vm.userObject,
                  "all-item-path-of-teams-items": _vm.allItemPathOfTeamsItems,
                  "current-item": _vm.currentItemObject,
                  "group-id": _vm.groupId,
                  "current-page-type": _vm.currentPageType,
                  "team-access-to-root-folder": _vm.teamAccessToRootFolder
                }
              })
            : _vm._e(),
          _c(
            "b-navbar-nav",
            { staticClass: "ml-auto" },
            [
              _vm.showHeaderActionButtons && _vm.groupId
                ? _c("header-action-buttons", {
                    staticClass: "d-flex align-items-center",
                    attrs: {
                      "user-object": _vm.userObject,
                      "group-id": _vm.groupId,
                      "privileges-for-item":
                        _vm.currentItemObject &&
                        _vm.currentItemObject.privilegesForItem.length
                          ? _vm.currentItemObject.privilegesForItem
                          : [],
                      "current-page-type": _vm.currentPageType
                    }
                  })
                : _vm._e(),
              _vm.userObject && _vm.groupId
                ? _c("header-navigation-right", {
                    attrs: {
                      "group-id": _vm.groupId,
                      privileges: _vm.currentGroupPrivileges,
                      "group-management-privileges": _vm.userObject
                        .groupIdsOfUser
                        ? _vm.userObject.groupIdsOfUser.length !== 0
                        : false,
                      "is-sys-admin": _vm.userObject.isSysAdmin
                    }
                  })
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }