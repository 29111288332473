<template>
  <b-navbar
    class="header"
    toggleable="md"
    type="dark"
    fixed="top"
  >
    <b-navbar-brand
      id="logo" to="/"
      class="m-0 p-0 mr-4"
    >
      <img
        class="m-3"
        src="@/assets/logo.png"
        alt="Webmag Logo"
      />
    </b-navbar-brand>

    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

    <b-collapse id="nav-collapse" is-nav class="">
      <header-breadcrumb
        v-if="userObject && currentPageType"
        :user-object="userObject"
        :all-item-path-of-teams-items="allItemPathOfTeamsItems"
        :current-item="currentItemObject"
        :group-id="groupId"
        :current-page-type="currentPageType"
        :team-access-to-root-folder="teamAccessToRootFolder"
      />

      <!-- Right aligned nav items -->
      <b-navbar-nav class="ml-auto">
        <header-action-buttons
          class="d-flex align-items-center"
          v-if="showHeaderActionButtons && groupId"
          :user-object="userObject"
          :group-id="groupId"
          :privileges-for-item="(currentItemObject && currentItemObject.privilegesForItem.length)
              ? currentItemObject.privilegesForItem : []"
          :current-page-type="currentPageType"
        />
        <header-navigation-right
          v-if="userObject && groupId"
          :group-id="groupId"
          :privileges="currentGroupPrivileges"
          :group-management-privileges=
              "(userObject.groupIdsOfUser ? userObject.groupIdsOfUser.length !== 0 : false)"
          :is-sys-admin="userObject.isSysAdmin"
        />
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>

<script>
import GetIdBySlugPath from '@/graphQlQueries/queries/getIdBySlugPath';
import UserDataAndAccess from '@/mixins/userDataAndAccess';

export default {
  name: 'Header',
  mixins: [UserDataAndAccess],
  components: {
    HeaderActionButtons: () => import('@/components/header/Buttons/HeaderActionButtons.vue'),
    HeaderBreadcrumb: () => import('@/components/header/HeaderBreadcrumb.vue'),
    HeaderNavigationRight: () => import('@/components/header/HeaderNavigationRight.vue'),
  },
  data() {
    return {
      currentItemObject: null,
      allItemPathOfTeamsItems: null,
      teamAccessToRootFolder: null,
    };
  },
  created() {
    this.usedInHeader = true;
  },
  watch: {
    userObject(val) {
      this.allItemPathOfTeamsItems = this.getAllTeamsItemPathOfAllItemsInAGroup(
        val.teamItemsOfUser,
      );
    },
  },
  computed: {
    showHeaderActionButtons() {
      if (this.currentPageType === 'folder' && !this.isGroupUser) {
        return true;
      }
      return !!(this.userObject && this.currentItemObject && this.currentPageType);
    },
    currentPageType() {
      let pageType = null;
      // remove a potential backslash at the end
      let pathOfPage = this.$route.path;
      if (this.$route.path.slice(-1) === '/') {
        pathOfPage = this.$route.path.substring(0, this.$route.path.length - 1);
      }
      if (this.currentItemObject) {
        if (this.currentItemObject.folder_id) {
          pageType = 'folder';
          if (pathOfPage.endsWith('folder-settings')) {
            pageType = 'folder-settings';
          }
        }
        if (this.currentItemObject.project_id) {
          pageType = 'project';
          if (pathOfPage.endsWith('project-settings')) {
            pageType = 'project-settings';
          }
        }
        if (this.currentItemObject.page_id) {
          pageType = 'page';
          if (pathOfPage.endsWith('page-settings')) {
            pageType = 'page-settings';
          }
        }
      }
      if (pathOfPage === '/settings') {
        pageType = 'general-settings';
      }
      if (pathOfPage === '/account-management') {
        pageType = 'group-settings';
      }
      if (pathOfPage === '/team-management') {
        pageType = 'team-settings';
      }
      if (pathOfPage === '/personalization-management') {
        pageType = 'personalization-settings';
      }
      if (pathOfPage === '/reports') {
        pageType = 'reports-settings';
      }
      if (pathOfPage === '/form-management') {
        pageType = 'form-settings';
      }
      if (this.$route.path === '/') {
        pageType = 'folder';
      }
      return pageType;
    },
  },
  apollo: {
    lookupItemQuery: {
      query: GetIdBySlugPath,
      variables() {
        const path = (this.$route.params.parametersOfUrl)
          ? `/${this.$route.params.parametersOfUrl}`
          : '/';
        return {
          groupId: this.groupId,
          path,
        };
      },
      update(data) {
        console.log('lookupItemQuery', data);
        if (data.item_lookup.length === 0) {
          return null;
        }
        const item = data.item_lookup[0];
        if (typeof item.breadcrumbs === 'string') {
          item.breadcrumbs = JSON.parse(item.breadcrumbs);
        }
        const breadcrumbs = this.prepareTheItemsForTheBreadcrumb(item.breadcrumbs);
        const { userGroupRoleIds } = this.userObject;
        const itemTeamRoles = this.getTheTeamRolesForItem(item.item_path);
        const itemAllRoleIds = [...new Set([
          ...userGroupRoleIds,
          ...itemTeamRoles,
        ])];
        const privilegesForItem = this.getAllPrivilegesByRoles(itemAllRoleIds);
        this.currentItemObject = {
          folder_id: item.folder_id,
          project_id: item.project_id,
          page_id: item.page_id,
          itemPath: item.item_path,
          privilegesForItem,
          breadcrumbs,
        };
        return data;
      },
      skip() {
        return !this.groupId || !this.userObject;
      },
      fetchPolicy: 'network-only',
    },
  },
  methods: {
    getTheTeamRolesForItem(itemPath) {
      const teamRoles = [];
      this.userObject.teamItemsOfUser.forEach((teamObject) => {
        teamObject.team.teams_items.some((teamItem) => {
          let teamItemItemPath = null;
          if (teamItem.folder) {
            teamItemItemPath = teamItem.folder.item_path;
          }
          if (teamItem.project) {
            teamItemItemPath = teamItem.project.item_path;
          }
          if (teamItem.page) {
            teamItemItemPath = teamItem.page.item_path;
          }
          if (itemPath.search(teamItemItemPath) !== -1 && !teamRoles.includes(teamObject.role_id)) {
            teamRoles.push(teamObject.role_id);
            return true;
          }
          return false;
        });
      });
      return teamRoles;
    },
    getAllTeamsItemPathOfAllItemsInAGroup(users_roles_teams) {
      const itemPathOfTeamItems = [];
      users_roles_teams.forEach((teamObject) => {
        teamObject.team.teams_items.forEach((teamItem) => {
          let itemPathOfItem = null;
          if (teamItem.folder && teamItem.folder.group_id === this.groupId) {
            itemPathOfItem = teamItem.folder.item_path;
            if (teamItem.folder.root === true) {
              this.teamAccessToRootFolder = true;
            }
          }
          if (teamItem.project && teamItem.project.group_id === this.groupId) {
            itemPathOfItem = teamItem.project.item_path;
          }
          if (teamItem.page && teamItem.page.group_id === this.groupId) {
            itemPathOfItem = teamItem.pages.item_path;
          }
          if (itemPathOfItem !== null && !itemPathOfTeamItems.includes(itemPathOfItem)) {
            itemPathOfTeamItems.push(itemPathOfItem);
          }
        });
      });
      return itemPathOfTeamItems;
    },
    prepareTheItemsForTheBreadcrumb(breadcrumbsObject) {
      // add the disable item for all breadcrumb items
      breadcrumbsObject.forEach((breadcrumb, index) => {
        if (breadcrumb.type === 'folder') {
          // check with all teams if the element is inside the path
          if (this.allItemPathOfTeamsItems) {
            // eslint-disable-next-line no-param-reassign
            breadcrumbsObject[index].disabled = true;
            if (this.userObject.isGroupUser || this.userObject.isSysAdmin) {
              // eslint-disable-next-line no-param-reassign
              breadcrumbsObject[index].disabled = false;
            } else {
              this.allItemPathOfTeamsItems.some((teamsItemId) => {
                if (breadcrumb.item_path.search(teamsItemId) !== -1) {
                  // eslint-disable-next-line no-param-reassign
                  breadcrumbsObject[index].disabled = false;
                  return true;
                }
                return false;
              });
            }
          }
        } else {
          // eslint-disable-next-line no-param-reassign
          breadcrumbsObject[index].disabled = false;
        }
      });
      if (!this.$route.params.parametersOfUrl) {
        return null;
      }

      const urlSplitArray = this.$route.params.parametersOfUrl.split('/');
      const itemsForBreadcrumb = [];

      for (let i = 0; i < urlSplitArray.length; i++) {
        const to = (i !== 0)
          ? `${itemsForBreadcrumb[i - 1].to}/${urlSplitArray[i]}`
          : `/${urlSplitArray[i]}`;
        itemsForBreadcrumb.push({
          text: breadcrumbsObject[i + 1].name,
          to,
          active: false,
          type: breadcrumbsObject[i + 1].type,
          disabled: breadcrumbsObject[i + 1].disabled,
          itemPath: breadcrumbsObject[i + 1].item_path,
        });
      }

      // replace the last element with active == true
      if (itemsForBreadcrumb
        && itemsForBreadcrumb.length
        && this.currentPageType !== null
        && this.currentPageType.includes('settings') === false) {
        itemsForBreadcrumb[itemsForBreadcrumb.length - 1].active = true;
      }
      return itemsForBreadcrumb;
    },
  },
};
</script>

<style scoped lang="scss">
img {
  width: 33px;
  display: block;
}

.header {
  background-color: $site-background-color;
  border-bottom: 1px solid $header-border-color;
}

.navbar {
  padding: 0;

  .navbar-brand {
    background-color: $bg-header-dark;
  }

  .navbar-nav {
    height: 65px;
  }
}

//@media (min-width: 992px) {
/deep/ .dropdown-toggle::after {
  display: none !important;
}

//}
</style>
